body .swiper {
  width: 100%;
  height: 100%;
  overflow: visible;

  .swiper-button-next,
  .swiper-button-prev {
    color: $color__green;
  }
}

// hide default nav buttons
.gallery {
  .swiper-button-next:not(.gallery__nav),
  .swiper-button-prev:not(.gallery__nav) {
    display: none !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  color: #f2f2f2 !important;
  opacity: 0.4 !important;
}
