@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.layout {
  @include bp(0 tablet) {
    padding-top: 64px;
  }

  &.layout_notop {
    @include bp(0 tablet) {
      padding-top: 0;
    }
  }
}
