@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.header {
  background: $color__darkblue;
  position: relative;
  max-height: 72px;
  min-height: 64px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include bp(0 tablet) {
    position: fixed;
    top: 0;
    z-index: 999; // TODO: remake
  }

  &.header_scrolled {
    @include bp(0 tablet) {
      background-color: rgba(0, 0, 0, 0);
    }

    .header__logo {
      @include bp(0 tablet) {
        display: none;
      }
    }

    .header__btn {
      margin-left: auto;
    }
  }

  &.header_hidden {
    button {
      display: none;
    }

    @include bp(tablet) {
      display: none;
    }
  }
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  @include bp(0 tablet) {
    display: none;
  }
}

.header__logo {
  display: flex;
  align-items: center;

  a {
    font-size: 0;
  }

  img {
    width: 134px;
    height: 40px;
    margin-right: 25px;
  }

  p {
    font-size: 12px;
    line-height: 19px;
    font-weight: 500;
    margin: 0;

    @include bp(0 desktop-large) {
      display: none;
    }
  }
}

.header__links {
  display: flex;
  list-style: none;

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.28px;
    padding-top: 12px;
    padding-bottom: 16px;
  }

  li + li {
    @include bp(tablet) {
      margin-left: 60px;
    }
    @include bp(desktop-large) {
      margin-left: 89px;
    }
  }
}

.header__top {
  // background: $color__darkblue;
  display: flex;
  position: relative;
  flex-grow: 1;

  .header__topwrap {
    padding-top: 6px;
    padding-bottom: 6px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  @include bp(tablet) {
    display: none;
    background: none;
  }
}

.header__exantelogo {
  height: 40px;

  @include bp(mobile) {
    width: 64px;
    height: 64px;
  }

  @include bp(tablet) {
    width: 40px;
    height: 40px;
  }

  @include bp(desktop-wide) {
    width: 44px;
    height: 44px;
  }
}

.header__btn {
  background: none;
  border: 0 none;
  cursor: pointer;
}

.header__mobile_menu_wrap {
  transition: all 0.3s ease-in-out;

  position: absolute;
  top: 0;
  width: 100%;
  background: $color__darkblue;
  z-index: -1;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;

  .header__topwrap {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-end;
  }

  .header__links {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: right;
    margin: 0;
  }

  @include bp(tablet) {
  }
}

.header__mobile_menu_wrap_opened {
  top: 100%;
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;

  .header__topwrap {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
