@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
// @import "./_base/_fonts.scss";
@import "./_vars.scss";
@import "./_header-underline.scss";
@import "./_decoratives.scss";
@import "./_headers.scss";
@import "./_colors.scss";
@import "./_mappy-bp.scss";
@import "./text-icon.scss";

html,
body {
  scroll-behavior: smooth;
  background-color: #252c2e;
  color: #fff;
  font-family: $font-family-main;
}

h1,
h2,
h3,
h4,
h4,
h5,
h6 {
  font-family: $font-family-main;
}

h4,
h5,
h6 {
  @include header;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

html {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  /* 1 */

  font-variant-numeric: lining-nums;
  -webkit-text-size-adjust: 100%;
  /* 2 */
  font-display: swap;
}

@media (min-width: 1440px) {
  html {
    font-size: 18px;
    line-height: 1.39;
  }
}

@media (min-width: 1680px) {
  html {
    font-size: 22px;
    line-height: 1.36;
  }
}

body {
  overflow-x: initial;
  position: relative;

  height: 100%;
  margin: 0;

  * {
    box-sizing: border-box;
  }
}

main {
  display: block;
}

hr {
  overflow: visible;
  /* 2 */
  /**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
}

b,
strong {
  font-weight: normal;
}

img {
  /**
  * prevent overflow by img
  */
  max-width: 100%;
  /**
  * Remove the border on images inside links in IE 10.
  */

  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  /**
  * 1. Change the font styles in all browsers.
  * 2. Remove the margin in Firefox and Safari.
  */
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */

  margin: 0;
  /* 2 */
}

button,
input {
  /**
 * Show the overflow in IE.
 * Show the overflow in Edge.
 */
  overflow: visible;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #ffffff;
  transition: background-color 9999s ease-in-out 0s;
}

button,
select {
  /**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * Remove the inheritance of text transform in Firefox.
 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
 * Correct the inability to style clickable types in iOS and Safari.
 */
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  /**
 * Remove the inner border and padding in Firefox.
 */

  border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  /**
 * Restore the focus styles unset by the previous rule.
 */
  outline: 1px dotted ButtonText;
}

textarea {
  /**
 * Remove the default vertical scrollbar in IE 10+.
 */
  overflow: auto;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  /**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  /* 2 */
  /**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

  -webkit-appearance: textfield;
  /* 1 */
}

[type="search"]::-webkit-search-decoration {
  /**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  /* 2 */
  /**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

  -webkit-appearance: button;
  /* 1 */
}

details {
  /*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
  display: block;
}

summary {
  /*
 * Add the correct display in all browsers.
 */
  display: list-item;
}

[hidden] {
  /**
 * Add the correct display in IE 10.
 */
  display: none;
}

/* Change Autocomplete styles in Chrome*/
@-webkit-keyframes autofill {
  to {
    color: $color__text;
    background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-animation-name: autofill;

  -webkit-animation-fill-mode: both;
}

a {
  text-decoration: none;

  color: $color__green;
}

b,
strong {
  font-weight: normal;
}

body.page {
  padding-top: 0;
}

p {
  font-size: inherit;
  line-height: inherit;
}

p+p {
  margin-top: initial;
}

big {
  font-size: 24px;
  line-height: 1.25;
}

@media (min-width: 1280px) {
  big {
    font-size: 28px;
    line-height: 1.43;
  }
}

:root {
  --cutted-angle: polygon(0 0,
      100% 0,
      100% calc(100% - 21.622px),
      calc(100% - 0.005px) calc(100% - 21.313px),
      calc(100% - 0.028px) calc(100% - 20.989px),
      calc(100% - 0.078px) calc(100% - 20.5px),
      calc(100% - 0.167px) calc(100% - 19.996px),
      calc(100% - 0.291px) calc(100% - 19.473px),
      calc(100% - 0.437px) calc(100% - 18.994px),
      calc(100% - 0.624px) calc(100% - 18.499px),
      calc(100% - 0.85px) calc(100% - 17.995px),
      calc(100% - 1.08px) calc(100% - 17.555px),
      calc(100% - 1.317px) calc(100% - 17.16px),
      calc(100% - 11.643px) calc(100% - 1.779px),
      calc(100% - 11.821px) calc(100% - 1.533px),
      calc(100% - 12px) calc(100% - 1.321px),
      calc(100% - 12.324px) calc(100% - 1px),
      calc(100% - 12.667px) calc(100% - 0.727px),
      calc(100% - 13px) calc(100% - 0.517px),
      calc(100% - 13.477px) calc(100% - 0.284px),
      calc(100% - 14px) calc(100% - 0.117px),
      calc(100% - 14.488px) calc(100% - 0.028px),
      calc(100% - 14.967px) 100%,
      0 100%);
}

.v-text-field {
  &>.v-input__control>.v-input__slot {
    &:before {
      border-width: 2px 0 0 0;
      border-color: #fff;
    }

    &:after {
      border: none !important;
      height: 2px;
      // background: linear-gradient(90deg, #1ae785 0%, #ccec82 132.26%);
      background: #25b778;
    }
  }
}

.MuiInput-root {
  // margin-top: 21px;

  &:before {
    border-width: 2px !important;
    border-color: #ffffff !important;
  }

  &:after {
    opacity: 0.6;
    border-bottom: 2px solid rgba($color__green--light, 0.7) !important;
    // border-bottom: 2px solid $color__green--light !important;
  }
}


.MuiFormControl-root {
  // width: 100% !important;

  label {
    color: #fff !important;
    opacity: 0.6;
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;

    height: 36px !important;

    @include bp(mobile) {
      font-size: 40px;
      line-height: 64px;

      height: 64px !important;
    }

    @include bp(tablet) {
      font-size: 20px;
      line-height: 32px;

      height: 36px !important;
    }

    @include bp(desktop-large) {
      font-size: 22px;
    }
  }

  input {
    padding: 8px 0;
    color: #ffffff;
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;
    max-height: 40px;

    @include bp(mobile) {
      font-size: 40px;
      line-height: 64px;
      max-height: 64px;
    }

    @include bp(tablet) {
      font-size: 20px;
      line-height: 32px;
      max-height: 40px;
    }

    @include bp(desktop-large) {
      font-size: 22px;
      max-height: 42px;
    }
  }
}

.v-messages__message {
  @include bp(mobile) {
    font-size: 16px;
  }
}

.v-text-field .v-label--active {
  @include bp(mobile) {
    font-size: 24px;
    line-height: 64px;

    transform: translateY(-28px) scale(0.75);
  }

  @include bp(tablet) {
    font-size: 12px;
    line-height: 32px;

    transform: translateY(-18px) scale(1);
  }
}

.MuiFormHelperText-root.Mui-error {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  caret-color: #ffffff !important;
  opacity: 0.6;
}

.MuiInput-root.Mui-error:before {
  border-color: #ff9292 !important;
}

.animate {
  transition-delay: 0.25s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;

  will-change: transform, opacity;

  &.fade {
    opacity: 0;
  }

  &.animate-active {
    &.fade {
      opacity: 1;
    }
  }
}

.slide-right {
  @include bp(tablet) {
    transform: translateX(100%);
  }
}

.slide-left {
  @include bp(tablet) {
    transform: translateX(-100%);
  }
}

.slide-up {
  @include bp(tablet) {
    transform: translateY(100%);
  }
}

.slide-down {
  @include bp(tablet) {
    transform: translateY(-100%);
  }
}

.slide-up.animate-active,
.slide-down.animate-active {
  @include bp(tablet) {
    transform: translateY(0);
  }
}

.slide-right.animate-active,
.slide-left.animate-active {
  @include bp(tablet) {
    transform: translateX(0);
  }
}

.zoom-in {
  transform: scale(0);
}

.zoom-in.animate-active {
  transform: scale(1);
}

// :root,
// [data-theme="default"] {
//   // each item in color map
//   @each $key, $value in $colors {
//     --colors-#{$key}: #{$value};
//   }
// }

// .nuxt_page {
//   overflow: hidden;
// }

.container {
  @include bp(desktop-wide) {
    max-width: 1456px !important;
  }
}

body.menuOpen {
  height: 100vh;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color__darkblue, 0.6);
  }
}

body.formOpen {
  height: 100vh;
  overflow: hidden;
}

.col {
  /* Why paddings https://zellwk.com/blog/responsive-grid-system/ */
  /*
    TODO: reseach for subgrid
    calc(100vw - var($xcontainer__padding, 12px) * 2)
    */
  $width-base: Min(#{$max-container-width}, 100%);
  /* min cause we don't need more cols then we have in grid */
  $width: calc((#{$width-base} / #{$cols}) * Min(#{$cols}, var(--cols-item)));
  $width-sm: calc((#{$width-base} / #{$cols-sm}) * Min(#{$cols-sm}, var(--cols-item-sm)));
  $width-md: calc((#{$width-base} / #{$cols-md}) * Min(#{$cols-md}, var(--cols-item-md)));
  $width-lg: calc((#{$width-base} / #{$cols-lg}) * Min(#{$cols-lg}, var(--cols-item-lg)));
  $width-xl: calc((#{$width-base} / #{$cols-xl}) * Min(#{$cols-xl}, var(--cols-item-xl)));

  // /* for nesting width without extra props */
  // $item-width: var($width);
  // $item-width-sm: var($width-sm, var($item-width));
  // $item-width-md: var($width-md, var($item-width-sm));
  // $item-width-lg: var($width-lg, var($item-width-md));
  // $item-width-xl: var($width-xl, var($item-width-lg));
  flex: 0 0 $width;
  width: 100%;
  max-width: $width;
  padding: calc(#{$xgrid__gutter} / 2);

  @include bp(tablet-small) {
    /* calc(100vw - var($xcontainer__padding-sm, 64px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-sm} / 2);

    &[style*="--cols-item-sm"] {
      flex: 0 0 $width-sm;
      max-width: $width-sm;
    }
  }

  @include bp(desktop) {
    /* calc(100vw - var($xcontainer__padding-md, 96px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-md} / 2);

    &[style*="--cols-item-md"] {
      flex: 0 0 $width-md;
      max-width: $width-md;
    }
  }

  @include bp(desktop-large) {
    /* calc(100vw - var($xcontainer__padding-lg, 96px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-lg} / 2);

    &[style*="--cols-item-lg"] {
      flex: 0 0 $width-lg;
      max-width: $width-lg;
    }
  }

  @include bp(desktop-wide) {
    /* calc(100vw - var($xcontainer__padding-xl, 120px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-xl} / 2);

    &[style*="--cols-item-xl"] {
      flex: 0 0 $width-xl;
      max-width: $width-xl;
    }
  }

  &.col--noGutters {
    padding: 0;
  }
}

.meet--cyprus {
  background-image: url("/images/cyprus-bg-mobile.jpg");

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    background-image: url("/images/cyprus-bg-mobile@2x.jpg");
  }

  @supports (gap: 1px) {
    // Targeting just browsers which support the gap property ensures a very similar browser support to WebP images. REF: https://caniuse.com/flexbox-gap
    background-image: url("/images/cyprus-bg-mobile.webp");

    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background-image: url("/images/cyprus-bg-mobile@2x.webp");
    }
  }

  @include bp(tablet) {
    background-image: url("/images/cyprus-bg-tablet.jpg");

    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background-image: url("/images/cyprus-bg-tablet@2x.jpg");
    }

    @supports (gap: 1px) {
      // Targeting just browsers which support the gap property ensures a very similar browser support to WebP images. REF: https://caniuse.com/flexbox-gap
      background-image: url("/images/cyprus-bg-tablet.webp");

      @media (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
        background-image: url("/images/cyprus-bg-tablet@2x.webp");
      }
    }
  }

  @include bp(desktop-large) {
    background-image: url("/images/cyprus-bg-3.jpg");
    background-position: right top;

    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background-image: url("/images/cyprus-bg-3@2x.jpg");
    }

    @supports (gap: 1px) {
      // Targeting just browsers which support the gap property ensures a very similar browser support to WebP images. REF: https://caniuse.com/flexbox-gap
      background-image: url("/images/cyprus-bg-3.webp");

      @media (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
        background-image: url("/images/cyprus-bg-3@2x.webp");
      }
    }
  }

  @include bp(desktop-large) {
    background-position: right 40%;
  }
}

.meet--london {
  background-image: url("/images/horizon-london-2-mobile.jpg");
  background-position: 50% top;

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    background-image: url("/images/horizon-london-2-mobile@2x.jpg");
  }

  @supports (gap: 1px) {
    // Targeting just browsers which support the gap property ensures a very similar browser support to WebP images. REF: https://caniuse.com/flexbox-gap
    background-image: url("/images/horizon-london-2-mobile.webp");

    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background-image: url("/images/horizon-london-2-mobile@2x.webp");
    }
  }

  @include bp(desktop) {
    background-image: url("/images/horizon-london-2.jpg");
    background-position: right 20%;

    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background-image: url("/images/horizon-london-2@2x.jpg");
    }

    @supports (gap: 1px) {
      // Targeting just browsers which support the gap property ensures a very similar browser support to WebP images. REF: https://caniuse.com/flexbox-gap
      background-image: url("/images/horizon-london-2.webp");

      @media (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
        background-image: url("/images/horizon-london-2@2x.webp");
      }
    }
  }
}

.signup--cyprus {
  @include bp(tablet) {
    margin-top: 150px;
  }

  @include bp(desktop) {
    margin-top: 200px;
  }

  @include bp(desktop-large) {
    // margin-top: 352px;
    margin-top: 120px;
  }

  @include bp(desktop-wide) {
    margin-top: auto;
  }
}

.signup--cyprusoffline {
  @include bp(desktop-large) {
    margin-top: 120px;
  }
}

@import "./_swiper.scss";

// Temporary only for the polish events

@import "./_pagination.scss";

.posts-page {
  background-color: #f7f8f9;
  color: $color__darkblue;

  .posts-page__container {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
  }
}

.video {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

// label+.MuiInputBase-root.MuiInput-root {
//   margin-top: 0;
// }

.mui-phone-input-wrapper {
  width: 100%;
}

.MuiFormControl-root.mui-phone-input-search {
  input {
    color: currentColor;
  }
}